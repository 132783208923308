import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import RefProgImg from "../images/theme-photos-CGpifH-1.png"
import { Link } from "gatsby"

const PrivacyPolicy = ({ data }) => {
  return (
    <>
      <Layout>
        <SEO title="Privacy Policy" />
        <div className="about-summary-wrapper">
          <div className="about-summary-content">
            <Container style={{ padding: "100px 0" }}>
              <h2 className="text-center benefits-flair mb-5 text-white">
                Privacy Policy for SMS Communications
              </h2>
              <Row className="justify-content-center">
                <Col lg={9}>
                  {/* <h4 className="mb-5">
                Without our talented candidates like you, there would be no
                PeerSource! When you choose PeerSource to help you find the
                perfect position, we are committed to offer the best recruiting
                experience you have ever received.
              </h4> */}
                  <h3 className="text-white">
                    1. What Information Do We Collect?
                  </h3>
                  <p className="mb-5 text-white">
                    We collect your phone number when you verbally opt into
                    receiving SMS messages during an interview with our company.
                    We may also collect your phone number via our Contact Us
                    page when you opt into receiving SMS communications. We do
                    not collect other personally identifiable information
                    through SMS opt-ins.
                  </p>

                  <h3 className="text-white">
                    2. What Do We Use Your Information For?
                  </h3>
                  <p className="mb-3 text-white">
                    Your phone number is used exclusively for the following
                    purposes:
                  </p>
                  <ul className="mb-3 text-white">
                    <li className="text-white">Coordinating interviews</li>
                    <li className=" text-white">
                      Sending updates regarding your candidacy
                    </li>
                    <li className="text-white">
                      Sharing relevant information directly related to job
                      opportunities with our clients
                    </li>
                  </ul>
                  <p className="mb-5 text-white">
                    We will not sell, share, or use your phone number for other
                    purposes, including marketing.
                  </p>

                  <h3 className="text-white">
                    3. How Do We Protect Your Information?
                  </h3>
                  <p className="mb-5 text-white">
                    We take reasonable and appropriate measures to safeguard
                    your phone number and any related data from unauthorized
                    access, misuse, or disclosure. Our security protocols
                    include technical and administrative safeguards that comply
                    with applicable data protection regulations.
                  </p>

                  <h3 className="text-white">4. Do We Use Cookies?</h3>
                  <p className="mb-5 text-white">
                    No cookies are used in connection with SMS communications.
                    This SMS Privacy Policy does not include provisions for
                    website privacy practices, as we do not have a separate
                    privacy policy for website or online interactions.
                  </p>

                  <h3 className="text-white">
                    5. Do We Disclose Any Information to Outside Parties?
                  </h3>
                  <p className="mb-5 text-white">
                    We do not sell, trade, or transfer your phone number to
                    outside parties. This includes third-party marketers and
                    affiliates. Exceptions may include trusted partners or
                    service providers who assist in delivering SMS
                    communications, provided they adhere to strict
                    confidentiality and data protection agreements.
                  </p>

                  <h3 className="text-white">6. Third-Party Links</h3>
                  <p className="mb-5 text-white">
                    Our SMS communications do not include links to third-party
                    websites or services. .
                  </p>
                  <h3 className="text-white">
                    7. California Online Privacy Protection Act Compliance
                    (CalOPPA)
                  </h3>
                  <p className="mb-5 text-white">
                    We comply with CalOPPA, and users have the right to know how
                    their information is used. This privacy policy provides
                    clear information regarding the collection, use, and
                    protection of your phone number.
                  </p>
                  <h3 className="text-white">
                    8. Children’s Online Privacy Protection Act Compliance
                    (COPPA)
                  </h3>
                  <p className="mb-5 text-white">
                    We do not knowingly collect information from individuals
                    under 13 years of age. SMS communications are limited to
                    adults actively engaging with our company for job-related
                    purposes.
                  </p>
                  <h3 className="text-white">9. Legal & Licensing</h3>
                  <p className="mb-5 text-white">
                    We adhere to all applicable laws, including TCPA (Telephone
                    Consumer Protection Act) regulations, and ensure that your
                    consent is obtained before sending SMS communications.
                  </p>
                  <h3 className="text-white">10. Your Consent</h3>
                  <p className="mb-5 text-white">
                    By providing verbal opt-in consent or consent through our
                    Contact Us page, you agree to receive SMS communications
                    from our company. You may withdraw your consent at any time
                    by replying “STOP” to any SMS message or contacting us
                    directly.
                  </p>
                  <h3 className="text-white">
                    11. Changes to Our Privacy Policy
                  </h3>
                  <p className="mb-5 text-white">
                    This SMS Privacy Policy covers all privacy practices related
                    to SMS communications. We do not maintain separate privacy
                    policies for other forms of communication or online
                    interactions. Any significant changes to this policy will be
                    communicated through updates to our website or via SMS
                    notifications.
                  </p>
                  <h3 className="text-white">12. CCPA Privacy Notice</h3>
                  <p className="mb-5 text-white">
                    If you are a California resident, you are entitled to
                    additional rights under the California Consumer Privacy Act
                    (CCPA), including the right to request information about the
                    data we collect and to request its deletion. For more
                    information or to make a CCPA request, please contact us.
                  </p>
                  <h3 className="text-white">Contact Us</h3>
                  <p class="mb-5 text-white">
                    For questions or concerns regarding this SMS Privacy Policy,
                    please reach out to us at{" "}
                    <a class="text-white" href="tel:303-867-1111">
                      303-867-1111
                    </a>
                    .
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
